<template>
  <v-icon color="gray600" small v-text="iconName" />
</template>

<script>
export default {
  name: "IsPublishedIcon",

  props: {
    isPublished: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    iconName() {
      return this.isPublished ? "fas fa-eye" : "fas fa-eye-slash";
    },
  },
};
</script>
