<template>
  <ModuleSubPage fullWidth title="SEMINAR_MANAGEMENT.SEMINAR_VENUES.LIST.TITLE">
    <BaseHasPermission :permissionNames="['seminar_venue.read']">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-spacer />
            <v-col align-self="center" cols="12" md="5" lg="4" xl="3">
              <BaseTableSearchField
                v-model="filter.search"
                @triggerSearch="listSeminarVenues"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <SeminarVenuesTable
            :seminarVenues="seminarVenues"
            :loading="isLoading"
            @reload="listSeminarVenues"
            :options.sync="options"
            :server-items-length="meta.total"
          />
        </v-card-text>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import SeminarVenuesTable from "@views/Restricted/SeminarManagement/SeminarVenues/List/partials/SeminarVenuesTable.vue";
import hasPermission from "@mixins/hasPermission";

export default {
  name: "SeminarVenues",

  mixins: [hasPermission],

  components: {
    ModuleSubPage,
    SeminarVenuesTable,
  },

  data() {
    return {
      isLoading: true,
      seminarVenues: [],
      options: {
        page: 1,
        perPage: 15,
        sortBy: ["name1"],
        sortDesc: [false],
      },
      meta: {
        total: 0,
      },
      filter: {
        search: null,
      },
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.listSeminarVenues();
      },
    },
  },

  computed: {
    payload() {
      return {
        ...this.options,
        ...this.filter,
        perPage: this.options.perPage === -1 ? 0 : this.options.perPage,
      };
    },
  },

  methods: {
    async listSeminarVenues() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.listSeminarVenues(this.payload);
        this.seminarVenues = data.data;
        this.meta = data.meta;

        if (this.options.perPage === -1) {
          this.meta = {
            total: data.data.length,
          };
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.listSeminarVenues();
  },
};
</script>
