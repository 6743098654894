<template>
  <v-data-table
    :headers="headers"
    :items="seminarVenues"
    v-bind="$attrs"
    v-on="$listeners"
    item-key="id"
  >
    <template #[`item.actions`]="{ item }">
      <v-row no-gutters>
        <v-spacer />
        <v-col cols="auto" v-if="hasPermission({ permissionNames: ['seminar_venue.update'] })">
          <v-btn icon @click="goToEditPage(item.id)" small>
            <v-icon small>far fa-pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template #[`item.name1`]="{ item }">
      <span
        v-if="hasPermission({ permissionNames: ['seminar_venue.update'] })"
        class="clickable"
        @click="goToEditPage(item.id)"
      >
        {{ item.name1 }}
      </span>

      <span v-else>
        {{ item.name1 }}
      </span>
    </template>

    <template #[`item.isPublished`]="{ item }">
      <IsPublishedIcon :isPublished="item.isPublished" />
    </template>
  </v-data-table>
</template>

<script>
import hasPermission from "@mixins/hasPermission";
import IsPublishedIcon from "./IsPublishedIcon.vue";

export default {
  name: "SeminarVenuesTable",

  mixins: [hasPermission],

  components: {
    IsPublishedIcon,
  },

  props: {
    seminarVenues: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    async goToEditPage(seminarVenueId) {
      try {
        this.$router.push({ name: "SeminarVenuesUpdate", params: { seminarVenueId } });
      } catch {
        return;
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.LIST.TABLE.HEADER.NAME"),
          value: "name1",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.LIST.TABLE.HEADER.STREET"),
          value: "street",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.LIST.TABLE.HEADER.ZIP_CODE"),
          value: "zipCode",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.LIST.TABLE.HEADER.CITY"),
          value: "city",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.SEMINAR_VENUES.LIST.TABLE.HEADER.IS_PUBLISHED"),
          value: "isPublished",
          sortable: true,
        },

        {
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
  },
};
</script>
